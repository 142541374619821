body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

/* 滚动条的轨道 */
::-webkit-scrollbar {
  width: 5px; /* 设置滚动条的宽度 */
}

/* 滚动条的滑块 */
::-webkit-scrollbar-thumb {
  background-color: #cecece; /* 设置滑块的背景颜色 */
  border-radius: 2px; /* 设置滑块的圆角 */
}

/* 鼠标悬停在滚动条上时的滑块样式 */
::-webkit-scrollbar-thumb:hover {
  background-color: #cecece; /* 设置鼠标悬停时的滑块背景颜色 */
}

/* 滚动条的轨道背景 */
::-webkit-scrollbar-track {
  background-color: #f9f9f9; /* 设置轨道的背景颜色 */
}

/* 滚动条的轨道背景，鼠标悬停在滚动条上时 */
::-webkit-scrollbar-track:hover {
  background-color: #e0e0e0; /* 设置鼠标悬停时的轨道背景颜色 */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
